import React, { useState, useCallback, useEffect } from "react"
import Gallery from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images"
import Config from '../config'

import Container from "../components/container"
import Header from "../components/header"
import Navbar from "../components/navbar"
import SEO from "../components/seo"
import Footer from "../components/footer"

const PortfolioTemplate = ({ pageContext }) => {
  const { node } = pageContext
  const [photos, setPhotos] = useState([])
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)
  const [data, setData] = useState({})

  const getData = async stringID => {
    const id = stringID.match(/(\d+)/)[0]
    const call = await fetch(
      `${Config.apiUrl}/items/pages/${id}?access_token=${Config.token}&fields=*.*.*`
    )
    const response = await call.json()
    setData(response.data)
  }

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  useEffect(() => {
    if(data.images) {
      const photos = data.images.map(item => {
        console.log(item)
        return {
          src: item.directus_files_id.data.full_url,
          width: item.directus_files_id.width,
          height: item.directus_files_id.height,
        }
      })
      setPhotos(photos)
    }
  }, [data])

  useEffect(() => {
    getData(node.id)
  }, [node])

  return (
    <>
      <SEO
        title={node.meta_title}
        desc={node.meta_desc}
        keywords={node.meta_keywords}
        ogTitle={node.og_title}
        ogDesc={node.og_desc}
        ogImage={node.og_image?.data.full_url}
      />

      <Container>
        <Navbar />

        {data.banner_display && (
          <Header
            image={data.banner?.data.full_url}
            title={data.title}
            subtitle={data.subtitle}
          />
        )}

        <main id="main">
          <section>
            <div className="container">
              <div
                className="section-title"
                dangerouslySetInnerHTML={{ __html: data.content }}
              ></div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <Gallery photos={photos} onClick={openLightbox} />
                  <ModalGateway>
                    {viewerIsOpen ? (
                      <Modal onClose={closeLightbox}>
                        <Carousel
                          currentIndex={currentImage}
                          views={photos.map(x => ({
                            ...x,
                            srcset: x.srcSet,
                            caption: x.title,
                          }))}
                        />
                      </Modal>
                    ) : null}
                  </ModalGateway>
                </div>
              </div>
            </div>
          </section>
        </main>

        <Footer />
      </Container>
    </>
  )
}

export default PortfolioTemplate
